import React, { useEffect } from 'react';

const SpeedRfp = () => {
  useEffect(() => {
    // Since the script is loaded asynchronously, we need to check if the global variable is available.
    if (typeof window !== 'undefined' && typeof window.srfp === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://www.speedrfp.com/irfps/js/widget/';
      script.async = true;
      document.body.appendChild(script);
      
      script.onload = () => {
        const options = {};
        options.display = "overlay";
        options.key = "8d93c3b9-4306-4bde-eba9-41ea15fc7b0a"; 
        options.uid = "KVB8Z";
		options.container = "#rfp-button";
        const rfp_widget = new window.srfp.widget(options);
		console.log(rfp_widget) // dodge warning for unused variable. Variable declaration follows documentation.
      };

	  const styleElement = document.createElement('style');
	  styleElement.innerHTML = `
		#rfp {
		  background-color: #f5f5f5;
		  padding: 20px;
		  border: 1px solid #ccc;
		  border-radius: 5px;
		}
	  `;
	  document.head.appendChild(styleElement);
    }
  }, []);

  return <div className='text-center'><div id="rfp-button"></div></div>;
};

export default SpeedRfp;
